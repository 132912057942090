import { createGlobalStyle } from 'styled-components';

import RecoletaRegularWOFF2 from '../assets/fonts/recoleta/Recoleta-Regular.woff2';
import RecoletaRegularWOFF from '../assets/fonts/recoleta/Recoleta-Regular.woff';

import RecoletaMediumWOFF2 from '../assets/fonts/recoleta/Recoleta-Medium.woff2';
import RecoletaMediumWOFF from '../assets/fonts/recoleta/Recoleta-Medium.woff';

import RecoletaBoldWOFF2 from '../assets/fonts/recoleta/Recoleta-Bold.woff2';
import RecoletaBoldWOFF from '../assets/fonts/recoleta/Recoleta-Medium.woff';

import BiotifWOFF2 from '../assets/fonts/biotif/Biotif-Regular.woff2';
import BiotifWOFF from '../assets/fonts/biotif/Biotif-Regular.woff';
import BiotifTTF from '../assets/fonts/biotif/Biotif-Regular.ttf';

import BiotifMediumWOFF2 from '../assets/fonts/biotif/Biotif-Medium.woff2';
import BiotifMediumWOFF from '../assets/fonts/biotif/Biotif-Medium.woff';
import BiotifMediumTTF from '../assets/fonts/biotif/Biotif-Medium.ttf';

import BiotifSemiBoldWOFF2 from '../assets/fonts/biotif/Biotif-SemiBold.woff2';
import BiotifSemiBoldWOFF from '../assets/fonts/biotif/Biotif-SemiBold.woff';
import BiotifSemiBoldTTF from '../assets/fonts/biotif/Biotif-SemiBold.ttf';

const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: "Recoleta-Regular";
    font-weight: 400;
    font-style: normal;
    src: local('Recoleta-Regular'),
    url(${RecoletaRegularWOFF2}) format('woff2'),
    url(${RecoletaRegularWOFF}) format('woff');
  }
  @font-face {
    font-family: "Recoleta-Medium";
    font-weight: 500;
    font-style: normal;
    src: local('Recoleta-Medium'), 
    url(${RecoletaMediumWOFF2}) format('woff2'),
    url(${RecoletaMediumWOFF}) format('woff');
  }
  @font-face {
    font-family: "Recoleta-Bold";
    font-weight: 700;
    font-style: normal;
    src: local('Recoleta-Bold'), 
    url(${RecoletaBoldWOFF2}) format('woff2'),
    url(${RecoletaBoldWOFF}) format('woff');
  }

  @font-face {
    font-family: 'Biotif';
    font-weight: 400;
    font-style: normal;
    src: local('Biotif-Regular'), 
    url(${BiotifWOFF2}) format('woff2'),
    url(${BiotifWOFF}) format('woff'),
    url(${BiotifTTF}) format('ttf');
  }
  @font-face {
    font-family: 'Biotif';
    font-weight: 500;
    font-style: normal;
    src: local('Biotif-Medium'), 
    url(${BiotifMediumWOFF2}) format('woff2'),
    url(${BiotifMediumWOFF}) format('woff'),
    url(${BiotifMediumTTF}) format('ttf');
  }
  @font-face {
    font-family: 'Biotif';
    font-weight: 600;
    font-style: normal;
    src: local('Biotif-SemiBold'), 
    url(${BiotifSemiBoldWOFF2}) format('woff2'),
    url(${BiotifSemiBoldWOFF}) format('woff'),
    url(${BiotifSemiBoldTTF}) format('ttf');
  }

`;

export default GlobalFonts;
