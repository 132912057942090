import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import de from './translations/de.json';

const resources = {
  en,
  de,
};

export const availableLanguages = Object.keys(resources);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    defaultNS: 'common',
    fallbackLng: 'en',
    returnEmptyString: false,
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'localStorage'],

      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang',

      // cache user language on
      caches: ['localStorage'],
    },
  });
