export const DarkTheme = {
  mainBackground: '#161616',
  headerBg: '#000000',
  titleH1: '#fff',
  boutiqueText: '#fff',
  logoIcon: '#D89477',
  logoText: '#FFFFFF',
  headerLink: '#FFFFFF',
  headerLinkHover: '#D89477',
  language: 'rgba(216, 148, 119, 0.5)',
  languageActive: '#D89477',
  iconDefault: '#2A2A2A',
  iconUnderline: '#D89477',
  buttonPrimary: '#D89477',
  buttonPrimaryArrow: '#fff',
  buttonPrimaryText: '#121C1C',
  ticker: '#2A2A2A',
  titleH2: '#fff',
  titleH3: '#fff',
  titleH4: '#fff',
  text: '#696E76',
  list: '#D89477',
  marker: '#696E76',
  expBg: '#111111',
  expIcon: '#D89477',
  expArrowIcon: '#2A2A2A',
  buttonSecondary: '#2A2A2A',
  buttonSecondaryHover: '#D89477',
  buttonSecondaryText: '#fff',
  buttonSecondaryTextHover: '#D89477',
  buttonSecondaryArrow: '#D89477',
  buttonTertiaryArrow: '#111A1B',
  buttonTertiary: '#D89477',
  buttonTertiaryText: '#121C1C',
  brandCircle: '#2A2A2A',
  brand: '#D89477',
  border: 'rgba(105, 110, 118, 0.4)',
  footerLink: '#696E76',
  linkEmail: '#161616',
  notFoundIcon: '#fff',
  gamburger: '#fff',
  bgPhoto: '#fff',
  stroke: '#D89477',
};

export const LightTheme = {
  mainBackground: '#F0D9CC',
  headerBg: '#DDA99D',
  titleH1: '#24324F',
  boutiqueText: '#24324F',
  logoIcon: '#24324F',
  logoText: '#24324F',
  headerLink: '#24324F',
  headerLinkHover: '#fff',
  language: 'rgba(36, 50, 79, 0.5)',
  languageActive: '#24324F',
  iconDefault: '#DDA99D',
  iconUnderline: '#DDA99D',
  buttonPrimary: '#24324F',
  buttonPrimaryArrow: '#fff',
  buttonPrimaryText: '#fff',
  ticker: '#DDA99D',
  titleH2: '#24324F',
  titleH3: '#24324F',
  titleH4: '#24324F',
  text: '#868A91',
  list: '#24324F',
  marker: '#DDA99D',
  expBg: '#F4E2D8',
  expIcon: '#24324F',
  expArrowIcon: '#DDA99D',
  buttonSecondary: '#DDA99D',
  buttonSecondaryHover: '#24324F',
  buttonSecondaryText: '#24324F',
  buttonSecondaryTextHover: '#24324F',
  buttonSecondaryArrow: '#24324F',
  buttonTertiaryArrow: '#111A1B',
  buttonTertiary: '#DDA99D',
  buttonTertiaryText: '#121C1C',
  brandCircle: '#DDA99D',
  brand: '#24324F',
  border: 'rgba(36, 50, 79, 0.15)',
  footerLink: '#24324F',
  linkEmail: 'rgba(36, 50, 79, 0.05)',
  notFoundIcon: '#cc998d',
  gamburger: '#24324F',
  bgPhoto: '#DDA99D',
  stroke: '#DDA99D',
};

export const GreenTheme = {
  mainBackground: '#CFD7D2',
  headerBg: '#ADBBB5',
  titleH1: '#D89477',
  boutiqueText: '#111A1B',
  logoIcon: '#111A1B',
  logoText: '#000000',
  headerLink: '#111A1B',
  headerLinkHover: '#D89477',
  language: 'rgba(17, 26, 27, 0.5)',
  languageActive: '#111A1B',
  iconDefault: '#ADBBB5',
  iconUnderline: '#D89477',
  buttonPrimary: '#111A1B',
  buttonPrimaryArrow: '#D89477',
  buttonPrimaryText: '#fff',
  ticker: '#ADBBB5',
  titleH2: '#111A1B',
  titleH3: '#111A1B',
  titleH4: '#111A1B',
  text: '#868A91',
  list: '#111A1B',
  marker: '#D89477',
  expBg: '#DCE3E0',
  expIcon: '#D89477',
  expArrowIcon: '#FFFFFF',
  buttonSecondary: '#D89477',
  buttonSecondaryHover: '#24324F',
  buttonSecondaryText: '#24324F',
  buttonSecondaryTextHover: '#24324F',
  buttonSecondaryArrow: '#111A1B',
  buttonTertiaryArrow: '#111A1B',
  buttonTertiary: '#D89477',
  buttonTertiaryText: '#121C1C',
  brandCircle: '#ADBBB5',
  brand: '#111A1B',
  border: 'rgba(36, 50, 79, 0.15)',
  footerLink: '#111A1B',
  linkEmail: 'rgba(17, 26, 27, 0.05)',
  notFoundIcon: '#9baba4',
  gamburger: '#111A1B',
  bgPhoto: '#ADBBB5',
  stroke: '#D89477',
};
