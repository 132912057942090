import { ReactComponent as CircleLogo } from '../assets/img/circleLogo.svg';
import { ReactComponent as Logo } from '../assets/img/logo.svg';
import { ReactComponent as LangArrow } from '../assets/img/langArr.svg';
import { ReactComponent as PlanetIcon } from '../assets/img/planet.svg';
import { ReactComponent as ArrowRightIcon } from '../assets/img/arrRight.svg';
import { ReactComponent as ArrowDarkICon } from '../assets/img/arrowDark.svg';
import { ReactComponent as ArrowShortIcon } from '../assets/img/arrowShort.svg';
import { ReactComponent as SnowflakeIcon } from '../assets/img/snowflake.svg';
import { ReactComponent as ExpIcon1 } from '../assets/img/exp1.svg';
import { ReactComponent as ExpIcon2 } from '../assets/img/exp2.svg';
import { ReactComponent as ExpIcon3 } from '../assets/img/exp3.svg';
import { ReactComponent as ExpIcon4 } from '../assets/img/exp4.svg';
import { ReactComponent as ExpArrIcon1 } from '../assets/img/expArr1.svg';
import { ReactComponent as ExpArrIcon2 } from '../assets/img/expArr2.svg';
import { ReactComponent as ExpArrIcon3 } from '../assets/img/expArr3.svg';
import { ReactComponent as PlainIcon } from '../assets/img/plain.svg';
import { ReactComponent as DrawingIcon } from '../assets/img/btnSolid.svg';
import { ReactComponent as BrandCircleIcon1 } from '../assets/img/brandCircle1.svg';
import { ReactComponent as BrandCircleIcon2 } from '../assets/img/brandCircle2.svg';
import { ReactComponent as BrandCircleIcon3 } from '../assets/img/brandCircle3.svg';
import { ReactComponent as BrandCircleIcon4 } from '../assets/img/brandCircle4.svg';
import { ReactComponent as BrandCircleIcon5 } from '../assets/img/brandCircle5.svg';
import { ReactComponent as BrandIcon1 } from '../assets/img/brand1.svg';
import { ReactComponent as BrandIcon2 } from '../assets/img/brand2.svg';
import { ReactComponent as BrandIcon3 } from '../assets/img/brand3.svg';
import { ReactComponent as BrandIcon4 } from '../assets/img/brand4.svg';
import { ReactComponent as BrandIcon5 } from '../assets/img/brand5.svg';
import { ReactComponent as PackIcon } from '../assets/img/pack.svg';
import { ReactComponent as InstagramIcon } from '../assets/img/inst.svg';
import { ReactComponent as TelegramIcon } from '../assets/img/telega.svg';
import { ReactComponent as FacebookIcon } from '../assets/img/facebook.svg';
import { ReactComponent as UpIcon } from '../assets/img/up.svg';
import { ReactComponent as NotFoundIcon } from '../assets/img/404.svg';
import { ReactComponent as SnowflakeOutlineIcon } from '../assets/img/snowflakeOutline.svg';
import { ReactComponent as CloseIcon } from '../assets/img/close.svg';
import { ReactComponent as ClipIcon } from '../assets/img/clip.svg';
import { ReactComponent as ArrTabIcon } from '../assets/img/arrTab.svg';
import { ReactComponent as MenuIcon } from '../assets/img/menu.svg';
import { ReactComponent as CookieIcon } from '../assets/img/cookie.svg';
import { ReactComponent as DecorIcon } from '../assets/img/decor.svg';

export const ICONS_MAP = {
  CircleIcon: CircleLogo,
  Logo: Logo,
  Triangle: LangArrow,
  Planet: PlanetIcon,
  ArrowDark: ArrowDarkICon,
  ArrowLong: ArrowRightIcon,
  ArrowShort: ArrowShortIcon,
  SnowFlake: SnowflakeIcon,
  Exp1: ExpIcon1,
  Exp2: ExpIcon2,
  Exp3: ExpIcon3,
  Exp4: ExpIcon4,
  ExpArr1: ExpArrIcon1,
  ExpArr2: ExpArrIcon2,
  ExpArr3: ExpArrIcon3,
  Plain: PlainIcon,
  Drawing: DrawingIcon,
  BrandCircle1: BrandCircleIcon1,
  BrandCircle2: BrandCircleIcon2,
  BrandCircle3: BrandCircleIcon3,
  BrandCircle4: BrandCircleIcon4,
  BrandCircle5: BrandCircleIcon5,
  OhBoyLogo: BrandIcon1,
  CodingguysLogo: BrandIcon2,
  DXoneLogo: BrandIcon3,
  GreyapesLogo: BrandIcon4,
  KeyotaLogo: BrandIcon5,
  Pack: PackIcon,
  Telegram: TelegramIcon,
  Facebook: FacebookIcon,
  Instagram: InstagramIcon,
  Up: UpIcon,
  NotFound: NotFoundIcon,
  SnowflakeOutline: SnowflakeOutlineIcon,
  Close: CloseIcon,
  Clip: ClipIcon,
  ArrTab: ArrTabIcon,
  Menu: MenuIcon,
  Cookie: CookieIcon,
  Decor: DecorIcon,
};
