import styled from 'styled-components';
import BgImage from '../../assets/img/snowflakeOutline.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import { device } from '../../constants/breakpoints';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  width: 100%;
  z-index: 33;
  background: #000000 url(${BgImage}) no-repeat;
  display: flex;
  padding: 10px;
`;
export const Close = styled.button`
  padding: 10px;
  position: absolute;
  background: none;
  border: none !important;
  outline: none;
  color: white;
  right: 35px;
  top: 35px;
  transition: 0.2s;
  opacity: 0.5;
  z-index: 1;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  @media ${device.xxxl} {
    right: 18px;
    top: 12px;
  }
`;

export const CustomScrollbars = styled(Scrollbars)<{ isscrollvisible?: string }>`
  width: 100%;
  height: auto !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  & > div:nth-of-type(1) {
    display: flex;
    justify-content: center;
  }
  & ul {
    padding-left: 30px;
  }
  @media ${device.lg} {
    & > div:nth-of-type(1) {
      display: block;
      justify-content: center;
    }
  }
`;
