import styled from 'styled-components';
import BgImage from '../assets/img/bg.png';
import Title from '../components/Title';
import { device } from '../constants/breakpoints';
import ReactMarkdown from 'react-markdown';

export const Container = styled.div`
  background-color: ${(props) => props.theme.mainBackground};
  min-height: 100vh;
  background-image: url(${BgImage});
  font-size: 30px;
  color: ${(props) => props.theme.titleColor};
  transition: 0.1s;
`;

export const ImprintContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  color: white;
  height: 120px;
  max-width: 1200px;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 40px;
  @media ${device.lg} {
    padding-top: 50px;
    padding-bottom: 30px;
  }
`;
export const ImprintContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: white;
  max-width: 1200px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  @media ${device.lg} {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

export const LeftContainer = styled.div`
  flex: 50%;
  & p {
    color: #d89477;
    font-size: 18px;
  }
  & span {
    font-family: var(--font-secondary-regular);
    font-size: 18px;
    color: #696e76;
  }
`;

export const RightContainer = styled.div`
  flex: 50%;
  & h5 {
    font-family: var(--font-primary-regular);
    margin-bottom: 20px;
  }
  & p {
    font-family: var(--font-secondary-regular);
    font-size: 18px;
    color: #696e76;
    margin-bottom: 30px;
  }
  @media ${device.lg} {
    margin-top: 30px;
  }
`;

export const Heading = styled.h1`
  text-transform: uppercase;
  @media ${device.md} {
    font-size: 38px;
  }
`;

export const CustomTitle = styled(Title)`
  color: #fff;
  &:nth-of-type(1) {
    padding-bottom: 40px;
    padding-top: 80px;
  }
  @media ${device.lg} {
    &:nth-of-type(1) {
      padding-bottom: 0px;
      padding-top: 40px;
    }
  }
`;
export const Text = styled.p`
  color: rgba(134, 138, 144, 1);
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  font-family: 'Biotif';
  padding-top: 20px;
  padding-bottom: 58px;
  @media ${device.lg} {
    padding-bottom: 20px;
  }
`;

export const Markdown = styled(ReactMarkdown)`
  max-width: 1060px;
  padding: 40px 15px 0;

  @media ${device.md} {
    padding: 15px;
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }

  & h1 {
    padding-bottom: 30px;
  }

  & p {
    color: rgba(134, 138, 144, 1);
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    font-family: 'Biotif';
    padding-top: 20px;
    padding-bottom: 20px;
    @media ${device.lg} {
      padding-bottom: 20px;
    }
  }

  & ul {
    list-style: disc;
  }

  & ul > li {
    color: rgba(134, 138, 144, 1);
    font-size: 18px;
    &:last-child {
      padding-bottom: 20px;
    }
    &:first-child {
      padding-top: 20px;
    }
  }

  & a {
    color: #696e76;
    display: inline;
    transition: 0.2s;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.headerLinkHover};
    }
  }

  @media ${device.md} {
    & h1 {
      font-size: 38px;
    }
    & h2 {
      font-size: 28px;
    }
    & h3 {
      font-size: 22px;
    }
  }
`;
