import React from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import Stroke from '../../../components/Stroke';
import { ICONS_MAP } from '../../../constants/icons';
import BgImage from '../../../assets/img/man.png';
import BgImage2x from '../../../assets/img/man@2x.png';

import {
  Container,
  Text,
  StyledButton,
  CustomTitle,
  Box,
  Description,
  BoxText,
  Image,
  ImageContainer,
  Wrapper,
} from './styled';

interface Props {
  id?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ContactUs = ({ id, onClick }: Props): React.ReactElement => {
  const [isAnimateStart, setAnimateStart] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container id={id}>
        <CustomTitle variant="h2">
          <Fade
            bottom
            big
          >
            {t('contact.title')}
          </Fade>
        </CustomTitle>
        <Text>
          <Fade
            onReveal={() => setAnimateStart(true)}
            bottom
            delay={600}
          >
            {t('contact.text')}
          </Fade>
        </Text>
        <Box isAnimationStart={isAnimateStart}>
          <ICONS_MAP.SnowFlake />
          <Description>
            <Stroke
              delay="1s"
              top="5px"
              right="-15px"
              zIndex="0"
            />
            {t('contact.description')}
          </Description>
          <BoxText>{t('contact.offer')}</BoxText>
        </Box>
      </Container>
      <ImageContainer>
        <Fade left>
          <Image srcSet={`${BgImage} 1x, ${BgImage2x} 2x`} />
        </Fade>
        <StyledButton
          onClick={onClick}
          variant="tertiary"
          arrowDark
        >
          {t('contact.button')}
        </StyledButton>
      </ImageContainer>
    </Wrapper>
  );
};

export default ContactUs;
