import React from 'react';
import { ContentContainer } from './styled';
import ChangeYour from './ChangeYour';
import About from './About';
import Experience from './Experience';
import ContactUs from './ContactUs';
import Network from './Network';
import Jobs from './Jobs';
import ModalWrapper from '../../components/ModalWrapper';
import emailjs from '@emailjs/browser';
import { Form, Wrapper, CustomTabs, CustomButton } from './styled';
import Input from '../../components/Input';
import FileUploadButton from '../../components/FileUploadButton';
import Tab from '../../components/Tabs/Tab';
import Textarea from '../../components/Textarea';

const MainPage = (): React.ReactElement => {
  const [showModalContact, setShowModalContact] = React.useState(false);
  const formCandidate = React.useRef() as React.MutableRefObject<HTMLFormElement>;
  const formCompany = React.useRef() as React.MutableRefObject<HTMLFormElement>;

  const sendEmailCandidate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_6l6p0u5',
        'template_h3fcma6',
        formCandidate.current,
        'i0lQThF-lIssGf6TE'
      )
      .then(
        () => {
          setShowModalContact(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const sendEmailCompany = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_6l6p0u5',
        'template_qny8r9w',
        formCompany.current,
        'i0lQThF-lIssGf6TE'
      )
      .then(
        () => {
          setShowModalContact(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const changeYourMondayJobs = () => {
    window.location.href = 'https://jobs.changeyourmonday.com';
  };

  return (
    <ContentContainer id="main">
      <ModalWrapper
        onClose={() => setShowModalContact(false)}
        isShow={showModalContact}
      >
        <Wrapper>
          <CustomTabs
            label="Contact Us"
            text="Use form or text us via "
          >
            <Tab title="Company">
              <Form
                className="form-company"
                ref={formCompany}
                onSubmit={sendEmailCompany}
              >
                <Input
                  type="text"
                  name="user_company"
                  label="Company"
                />
                <Input
                  type="text"
                  name="full_name"
                  label="Full Name"
                />
                <Input
                  type="text"
                  name="user_job"
                  label="Job Position"
                />
                <Input
                  type="text"
                  name="user_email"
                  label="E-Mail"
                />
                <Textarea
                  name="message"
                  label="Share more information (optional)"
                />
                <FileUploadButton
                  name="job_description"
                  initialText="Attach job description"
                />
                <CustomButton
                  onClick={sendEmailCompany}
                  arrowPosition="bottom"
                  arrowShort
                >
                  Send a request
                </CustomButton>
              </Form>
            </Tab>
            <Tab title="Candidate">
              <Form
                className="form-candidate"
                ref={formCandidate}
                onSubmit={sendEmailCandidate}
              >
                <Input
                  type="text"
                  name="user_email"
                  label="E-Mail"
                />
                <Input
                  type="text"
                  name="user_name"
                  label="Full Name"
                />
                <Input
                  type="text"
                  name="user_job"
                  label="Focus in your current Job"
                />
                <Input
                  type="text"
                  name="expected_salary"
                  label="Expected Salary"
                />
                <FileUploadButton
                  name="candidate_cv"
                  initialText="Attach CV"
                />
                <CustomButton
                  onClick={sendEmailCandidate}
                  arrowPosition="bottom"
                  arrowShort
                >
                  Send a request
                </CustomButton>
              </Form>
            </Tab>
          </CustomTabs>
        </Wrapper>
      </ModalWrapper>
      <ChangeYour onClick={changeYourMondayJobs} />
      <About
        onClick={changeYourMondayJobs}
        id="about"
      />
      <Experience
        id="exp"
        onClick={changeYourMondayJobs}
      />
      <ContactUs
        id="contact"
        onClick={() => setShowModalContact(true)}
      />
      <Network id="network" />
      <Jobs
        id="jobs"
        onClick={changeYourMondayJobs}
      />
    </ContentContainer>
  );
};

export default MainPage;
