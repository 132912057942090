import React from 'react';
import { ICONS_MAP } from '../../constants/icons';
import { Container } from './styled';

interface ILogo {
  className?: string;
}

const Logo = ({ className }: ILogo): React.ReactElement => {
  return (
    <Container
      className={className}
      spy={true}
      smooth={true}
      to="main"
    >
      <ICONS_MAP.Logo />
    </Container>
  );
};

export default Logo;
