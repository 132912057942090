import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { DarkTheme, GreenTheme, LightTheme } from '../constants/colors';
import Header from '../components/Header';

import MainPage from './MainPage';
import NotFoundPage from './NotFoundPage';

import {
  Container,
  Markdown,
  ImprintContainer,
  LeftContainer,
  RightContainer,
  Heading,
  ImprintContentContainer,
} from './styled';
import Footer from '../components/Footer';
import Cookie from '../components/Cookies';
import ModalWrapper from '../components/ModalWrapper';

import { useTranslation } from 'react-i18next';

const IndexRoute = (): React.ReactElement => {
  const [theme, setTheme] = React.useState(DarkTheme);
  const [hideCookie, setHideCookie] = React.useState(false);
  const [showPolicyModal, setShowPolicyModal] = React.useState(false);
  const [showImprintModal, setShowImprintModal] = React.useState(false);

  const { t, i18n } = useTranslation();

  const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const target = event.target as HTMLElement;
    if (
      target.localName === 'a' ||
      target.localName === 'button' ||
      target.localName === 'input' ||
      target.localName === 'textarea' ||
      target.localName === 'button' ||
      document.querySelector('.modal')
    ) {
      return false;
    } else {
      if (theme === DarkTheme) {
        setTheme(GreenTheme);
      }
      if (theme === GreenTheme) {
        setTheme(LightTheme);
      }
      if (theme === LightTheme) {
        setTheme(DarkTheme);
      }
    }
  };

  const [legalDocument, setLegalDocument] = React.useState('');

  const legalDocumentLanguage = i18n.language.substring(0, 2);

  useEffect(() => {
    fetch(`/legal/privacy-policy/${legalDocumentLanguage}.md`)
      .then((res) => res.text())
      .then((md) => {
        setLegalDocument(md);
      });
  }, [i18n.resolvedLanguage]);

  return (
    <ThemeProvider theme={theme}>
      <Container onClick={(event) => onClick(event)}>
        <Suspense fallback={null}>
          <Route render={({ location }) => location.pathname !== '/404' && <Header />} />
          <Switch>
            <Route
              component={MainPage}
              path="/"
              exact
            />
            <Route
              path="/404"
              component={NotFoundPage}
            />
            <Redirect to="/404" />
          </Switch>
          <Route
            render={({ location }) =>
              location.pathname !== '/404' && (
                <Footer
                  onClickPolicy={() => setShowPolicyModal(true)}
                  onClickImprint={() => setShowImprintModal(true)}
                />
              )
            }
          />
          <ModalWrapper
            isShow={showPolicyModal}
            onClose={() => setShowPolicyModal(false)}
          >
            <Markdown>{legalDocument}</Markdown>
          </ModalWrapper>
          <ModalWrapper
            isShow={showImprintModal}
            onClose={() => setShowImprintModal(false)}
          >
            <ImprintContainer>
              <Heading>{t('footer.imprint.title')}</Heading>
              <ImprintContentContainer>
                <LeftContainer>
                  <p>{t('footer.imprint.company.title')}</p>
                  <span>{t('footer.imprint.company.address')}</span>
                  <p>{t('footer.imprint.registration.title')}</p>
                  <span>{t('footer.imprint.registration.registration_number')}</span>
                  <p>{t('footer.imprint.vat.title')}</p>
                  <span>{t('footer.imprint.vat.number')}</span>
                  <p>{t('footer.imprint.registration_instance.title')}</p>
                  <span>{t('footer.imprint.registration_instance.location')}</span>
                  <p>{t('footer.imprint.managing_director.title')}</p>
                  <span>{t('footer.imprint.managing_director.name')}</span>
                  <p>{t('footer.imprint.contact.title')}</p>
                  <span>{t('footer.imprint.contact.mail')}</span>
                  <p>{t('footer.imprint.content_responsible.title')}</p>
                  <span>{t('footer.imprint.managing_director.name')}</span>
                </LeftContainer>
                <RightContainer>
                  <h5>{t('footer.imprint.links.title')}</h5>
                  <p>{t('footer.imprint.links.text')}</p>
                  <h5>{t('footer.imprint.copyright.title')}</h5>
                  <p>{t('footer.imprint.copyright.text_p1')}</p>
                  <p>{t('footer.imprint.copyright.text_p2')}</p>
                  <h5>{t('footer.imprint.branding.title')}</h5>
                  <p>{t('footer.imprint.branding.text')}</p>
                </RightContainer>
              </ImprintContentContainer>
            </ImprintContainer>
          </ModalWrapper>
          <Route
            render={({ location }) =>
              location.pathname !== '/404' && (
                <Cookie
                  onClickPolicy={() => setShowPolicyModal(true)}
                  setShow={() => setHideCookie(false)}
                  isShow={hideCookie}
                />
              )
            }
          />
        </Suspense>
      </Container>
    </ThemeProvider>
  );
};

export default IndexRoute;
