import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
      --font-primary-regular: "Recoleta-Regular", sans-serif;
      --font-primary-regular-weight: 400;
      
      --font-primary-medium: "Recoleta-Medium", sans-serif;
      --font-primary-medium-weight: 500;
      
      --font-primary-bold: "Recoleta-Bold", sans-serif;
      --font-primary-bold-weight: 700;
      
      --font-secondary-regular: "Biotif-Regular", sans-serif;
      --font-secondary-regular-weight: 400;
      
      --font-secondary-medium: "Biotif-Medium", sans-serif;
      --font-secondary-medium-weight: 500;
      
      --font-secondary-semibold: "Biotif-SemiBold", sans-serif;
      --font-secondary-semibold-weight: 600;
    }
`;

export default GlobalStyle;
