export const device = {
  mobileS: `(max-width: px)`,
  mobileM: `(max-width: `,
  mobileL: `(max-width: `,
  md: `(max-width: 767px)`,
  lg: `(max-width: 1024px)`,
  xl: `(max-width: 1300px)`,
  xxl: `(max-width: 1600px)`,
  xxxl: `(max-width: 1800px)`,
  xxxxl: `(max-width: 1920px)`,
};
