import styled, { css } from 'styled-components';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import { device } from '../../../constants/breakpoints';
import { fadeRight } from '../../../constants/keyframes';

export const Container = styled.div`
  max-width: 1520px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 220px;
  & h2 {
    & .react-reveal {
      font-family: var(--font-primary-medium);
    }
  }
  & .react-reveal {
    font-family: var(--font-secondary-regular);
  }
  & i {
    font-family: var(--font-secondary-regular);
  }
  @media ${device.xxxl} {
    padding-top: 120px;
  }
  @media ${device.xxl} {
    max-width: 1200px;
    padding-bottom: 153px;
  }
  @media ${device.xl} {
    max-width: 1000px;
    padding-bottom: 153px;
  }
  @media ${device.lg} {
    display: block;
    padding-bottom: 79px;
    padding-top: 75px;
  }
  @media ${device.md} {
    padding-top: 75px;
    padding-bottom: 211px;
  }
`;
export const StyledButton = styled(Button)`
  position: relative;
  margin-top: 138px;
  z-index: 1;
  & i {
    max-width: 80px;
  }
  & > div {
    color: ${(props) => props.theme.buttonSecondaryArrow};
  }
  @media ${device.xxl} {
    margin-top: 14px;
    & > div {
      transform: rotate(-48deg);
      position: absolute;
      left: 87%;
      top: 14%;
    }
  }
  @media ${device.lg} {
    position: absolute;
    bottom: 76px;
    right: 21px;
    z-index: 2;
    & > div {
      transform: rotate(-103deg);
      left: 27%;
      top: -48%;
    }
    &:hover {
      & > div {
        transform: rotate(-103deg);
        left: 27%;
        top: -48%;
      }
    }
  }
  @media ${device.md} {
    bottom: 35px;
    right: 28px;
    z-index: 2;
    & > div {
      transform: rotate(-233deg);
      left: -54%;
      top: 19%;
    }
    &:hover {
      & > div {
        transform: rotate(-233deg);
        left: -54%;
        top: 19%;
      }
    }
  }
`;
export const Text = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  padding-top: 20px;
  padding-bottom: 38px;
  color: ${(props) => props.theme.text};
  font-family: 'Biotif';
  max-width: 290px;
  @media ${device.xxl} {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  @media ${device.lg} {
    max-width: 502px;
    padding-top: 22px;
    padding-bottom: 60px;
  }
  @media ${device.md} {
    padding-bottom: 37px;
  }
`;
export const CustomTitle = styled(Title)`
  @media ${device.lg} {
    text-transform: uppercase;
  }
`;
export const Description = styled.div`
  color: ${(props) => props.theme.titleH4};
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;

  display: block;
  max-width: 520px;
  position: relative;
  z-index: 1;
  padding-top: 41px;
  font-family: var(--font-primary-regular);
  @media ${device.xxl} {
    padding-top: 0px;
    margin-top: 91px;
  }
  @media ${device.lg} {
    max-width: none;
    margin-top: 80px;
    padding-right: 224px;
  }
  @media ${device.md} {
    margin-top: 39px;
    padding-right: 0;
  }
`;
export const Image = styled.img`
  width: 100%;
  position: relative;
  @media ${device.xxl} {
    width: 100%;
    padding: 0 10px 0px 50px;
  }
  @media ${device.xl} {
    width: 100%;
  }
  @media ${device.lg} {
    width: 100%;
    padding: unset;
  }
  @media ${device.md} {
    height: 100%;
  }
`;
export const TitleBox = styled.div`
  width: 22.5%;
  @media ${device.xxl} {
    width: 25%;
  }
  @media ${device.xl} {
    width: 30%;
  }
  @media ${device.lg} {
    width: 100%;
  }
`;
export const DescriptionBox = styled.div<{ isAnimationStart: boolean }>`
  width: 27.5%;
  padding-left: 52px;
  position: relative;
  opacity: 0;
  ${(props) =>
    props.isAnimationStart
      ? css`
          animation: 0.5s ${fadeRight} 2s linear forwards;
        `
      : ''}
  & > svg {
    color: ${(props) => props.theme.iconDefault};
    display: block;
    margin-left: 10px;
  }
  @media ${device.xxl} {
    width: 25%;
    padding-left: 15px;

    & > svg {
      width: 182px;
      height: 163px;
      position: absolute;
      top: 0;
      left: 47%;
      margin-left: -90px;
    }
  }
  @media ${device.xl} {
    width: 30%;
  }
  @media ${device.lg} {
    width: 100%;
    padding: 0;
    position: static;
    & > svg {
      width: 317px;
      height: 284px;
      position: absolute;
      top: 0;
      left: auto;
      margin-left: 0px;
      right: 25px;
    }
  }
  @media ${device.lg} {
    & > svg {
      display: none;
    }
  }
`;
